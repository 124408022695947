.c-btn {
    display:table;
    font-size: 20px;
    padding: 5px 15px;
    line-height: 30px;
    text-decoration: none;
    font-family: $raleway-bold;
    transition: background-color 1s ease;
    letter-spacing: 1px;
    text-transform: uppercase;
    position: relative;
    color: white;
}

.c-btn--red {
    background-color: color('red');
    &:hover {
        background-color: color('red', 'medium');
    }
}

.c-btn--blue {
    background-color: color('blue', 'medium');
    &:hover {
        background-color: color('blue');
    }
}

.c-btn--wings {
    &::before {
        content: '';
        width: 95px;
        height: 36px;
        background-image: url(/app/themes/sage/dist/images/wing.png);
        position: absolute;
        left: -115px;
        top: 2px;
        transform:scaleX(-1);
        -webkit-transform:scaleX(-1);
        -ms-transform:scaleX(-1);
        display: none;
        @include media(xs) {
            display: block;
        }
    }
    &::after {
        content: '';
        width: 95px;
        height: 36px;
        background-image: url(/app/themes/sage/dist/images/wing.png);
        position: absolute;
        right: -115px;
        top: 2px;
        display: none;
        @include media(xs) {
            display: block;
        }
    }
}
