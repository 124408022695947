
.c-attractions {
    padding: 60px 0px;
    background-image: url(/app/themes/sage/dist/images/bg-blue.png);
    background-repeat: repeat;
    border-bottom: 4px solid white;
    h1,h2,h3,h4,h5,h6,p,img {
        color: white;
        margin-bottom: 15px;
    }
    img {
        border: 6px solid white;
        width: 100%;
    }
    .c-btn {
        margin-top: 15px;
        @include media(md) {
            margin-top: 60px;
            margin-bottom: 15px;
        }
    }
}
