
.c-header__toggle {
    display: block;
    position: absolute;
    float: right;
    overflow: hidden;
    padding: 0;
    width: rem(45px);
    height: rem(40px);
    font-size: 0;
    text-indent: -9999px;
    appearance: none;
    box-shadow: none;
    border-radius: none;
    border: none;
    cursor: pointer;
    transition: background 0.3s;
    z-index: 1000;
    top: 0px;
    right: 0px;
    border-left: 4px solid white;
   @include media(md) {
       display: none;
   }
}
.c-header__toggle:focus {
    outline: none;
}

/////// design of icon controls


.c-header__toggle span {
    display: block;
    position: absolute;
    top: rem(18px);
    left: rem(7px);
    right: rem(7px);
    height: rem(4px);
    background-color: white;
}

.c-header__toggle span::before,
.c-header__toggle span::after {
    position: absolute;
    display: block;
    left: 0;
    width: 100%;
    height: rem(4px);
    background-color: white;
    content: "";
}

.c-header__toggle span::before {
    top: neg(rem(8px));
}

.c-header__toggle span::after {
    bottom: neg(rem(8px));
}

////////////////

.c-header__toggle--htx {
    background-color: color('blue','medium');
}

.c-header__toggle--htx span {
    transition: background 0s 0.3s;
}

.c-header__toggle--htx span::before,
.c-header__toggle--htx span::after {
    transition-duration: 0.3s, 0.3s;
    transition-delay: 0.3s, 0s;
}

.c-header__toggle--htx span::before {
    transition-property: top, transform;
}

.c-header__toggle--htx span::after {
    transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.c-header__toggle--htx.is-active {
    background-color: color('blue','medium');
}

.c-header__toggle--htx.is-active span {
    background-color: color('blue','medium');
}

.c-header__toggle--htx.is-active span::before {
    top: 0;
    transform: rotate(45deg);
}

.c-header__toggle--htx.is-active span::after {
    bottom: 0;
    transform: rotate(-45deg);
}

.c-header__toggle--htx.is-active span::before,
.c-header__toggle--htx.is-active span::after {
    transition-delay: 0s, 0.3s;
}
