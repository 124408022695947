.c-intro {
    padding: 30px 0px;
    h6,hr,.c-list,p:nth-child(2) {
        display: none;
        @include media(md) {
            display: block;
        }
    }

    h1,h2,h3,h4,h5,h6,p {
        text-align: center;
        padding-bottom: 15px;

    }
    .c-btn {
        margin: 15px auto;
    }
    .c-list {
        text-align: center;
        li {
            display: inline;
            padding:0px 15px;
            width: 30%;
            position: relative;
            display: inline-block;
            vertical-align: top;
        }
    }
}
