.c-content--main {
    padding: 30px 0px;
    p {
        padding-bottom: 30px;
        strong {
            color: color('red');
            font-size: inherit;
        }
        a {
            color: color('red');
            font-size: inherit;
        }
    }
    .c-btn {
        color: white;
        display: table;
        margin: 15px auto;
    }
    .o-col {
        @include media(xs) {
            padding-top: 15px;
            padding-bottom: 15px;
        }
    }
    .c-content__header {
        padding-bottom: 15px;
        h1 {
            text-align: center;
        }
        img {
            display: table;
            margin: 0px auto;
            margin-bottom: 15px;
            box-shadow: none;
            border:none !important;
        }
    }
    img {
        box-shadow: 0px 0px 10px rgba(black, .5);
        border: 5px solid white !important;
        display: block;
        margin: 0px auto;
    }
    iframe {
        padding-bottom: 30px;
    }
}
//Rates custom header
.page-id-14 {
    .c-content__header {
        margin-bottom: 15px;
        h1 {
            &::before {
                content: 'Resonable ';
            }
            &::after {
                content: ' - Great Value';
            }
        }

    }
}

// tablepress styles
.tablepress-id-1 td {
    vertical-align: top;
    width: auto !important;
    border-right: solid 2px color('grey') !important;

    @include media(md) {
        width: 24% !important;
    }
}
.tablepress-id-1 td:last-child {
    border: none !important;
}

// Gallery styles

.gallery .gallery-item{
            display: inline-block;
            padding: 0px 5px;
            overflow: hidden;
            margin-bottom: 15px;
            width: 50%;
            max-height: 150px;

        }
.gallery .gallery-item img {
    display: block;
    width: auto;
    margin-bottom: 15px;
    opacity: 1;
    -webkit-transition: -webkit-transform 1.5s ease-in-out;
    transition:transform 1.5s ease-in-out;
    height: auto;
    border-radius: 0;
    border:none;
    box-shadow: none;
}
.gallery .gallery-item img:hover {
    opacity: .7;
}


@media (min-width:1024px){
    .gallery .gallery-item{
        width: 25%;
    }
}
