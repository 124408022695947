//primary
.c-nav__list {
    list-style: none;
    width: auto;
    position: absolute;
    display: table;
    top: 75px;
    display: none;
    @include media(md) {
        display: table;
    }
    li {
        display: inline;
        width: auto;
        padding: 40px 8px;
        position: relative;
        &:hover {
            .sub-menu {
                display: block;
            }
        }
        a {
            color: color('blue');
            text-decoration: none;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 1px;
            font-family: $raleway-bold;
        }
    }
}



// submenu
.c-nav__list li ul {
    padding: 0px 15px;
    display: none;
    position: absolute;
    z-index: 100;
    top: 70%;
    min-width: 200px;
    list-style: none;
    left: 0px;
    transition: background-color 1s;
    right: auto;
    background-color: white;
    li {
        display: block;
        padding: 15px 0px;
        &::before {
            display: none;
        }
        a {
            border-bottom: none;
        }
    }
}

.c-header__left-menu {
    .c-nav__list {
        text-align: left;
        width: auto;
        padding: 0px;
        left: 0px;
        li {
            padding-right: 15px;
            @include media(lg) {
                padding-right: 25px;
            }
            &::before {
                content: '|';
                position: absolute;
                right: 5px;
                color: color('red');
                font-weight: bold;
            }
            &:nth-last-child(1) {
                &::before {
                    display: none;
                }
            }
        }
    }
}
.c-header__right-menu {
    .c-nav__list {
        text-align: left;
        width: auto;
        padding: 0px;
        right: 0px;
        left: auto;
        li {
            padding-left: 8px;
            @include media(lg) {
                padding-left: 18px;
            }
            &::before {
                content: '|';
                font-weight: bold;
                position: absolute;
                left:0px;
                color: color('red');
            }
            &:nth-child(1) {
                &::before {
                    display: none;
                }
            }
            ul {
                left: 0px;
                right: auto;
                li {
                    padding-right: 0px;
                    padding-left: 0px;
                }
            }
        }
    }
}
.c-header__mobile {
    display: none;
    width: 100%;
    position: fixed;
    z-index: 160;
    height: 100%;
    background-image: url(/app/themes/sage/dist/images/bg-red.png);
    background-repeat: repeat;
    top: 0px;
    margin: 0px auto;
    overflow-x: scroll;
    ul {
        list-style: none;
        margin-top: rem(20px);
        height: auto;
        padding-top: 80px;
        li {
            text-align: center;
            .sub-menu {
                position: relative;
                left: rem(0px);
                margin-left: rem(0px);
                display: block;
                margin-top: 0px;
                display: none;
                li {
                    padding: rem(0px) rem(0px);
                    padding-left: rem(10px);
                }
            }
            a {
                text-decoration: none;
                font-size: rem(20px);
                color: color('blue', 'medium');
                line-height: rem(40px);
                text-transform: uppercase;
                &:hover {
                    color: color('orange');
                }
            }
        }
    }
}
.c-header__menu {
    position:relative;
    width: 100%;
    height: 100%;
    top: 0px;
    padding: 0;
    z-index: 100;
    background-image: url(/app/themes/sage/dist/images/bg-red.png);
    background-repeat: repeat;
    background-position: center;
    margin-bottom: 160px;
    .c-btn {
        display: table;
        margin: 0px auto;
        position: relative;
        border: 4px solid white;
    }
    .m-nav__list {
        display: block;
        width: 100%;
        padding: 0px;
        background-image: url(/app/themes/sage/dist/images/bg-red.png);
        background-repeat: repeat;
        background-position: center;
        margin-bottom: 15px;
        overflow-y: hidden;
        li {
            a {
                color: white;
                font-family: $raleway-bold;
            }
        }
    }
    .c-mobile__number {
        display: block;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 22px;
        font-family: $raleway-bold;
        padding-bottom: 15px;
    }
}
.c-nav--mobile {
    position: relative;
    z-index: 250;
}
.menu-mobile-navigation-container {
    background-color: white;
    position: relative;
    left: 0px;
    width: 100%;
    @include media(xs) {
        width: 100%;
    }
}
