///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }


@font-face {
font-family: 'Merriweather-Black';
src: url('../../dist/fonts/Merriweather-Black.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Merriweather-BlackItalic';
src: url('../../dist/fonts/Merriweather-BlackItalic.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Merriweather-Bold';
src: url('../../dist/fonts/Merriweather-Bold.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Merriweather-BoldItalic';
src: url('../../dist/fonts/Merriweather-BoldItalic.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Merriweather-Italic';
src: url('../../dist/fonts/Merriweather-Italic.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Merriweather-Light';
src: url('../../dist/fonts/Merriweather-Light.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Merriweather-LightItalic';
src: url('../../dist/fonts/Merriweather-LightItalic.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Merriweather-Regular';
src: url('../../dist/fonts/Merriweather-Regular.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Raleway-Black';
src: url('../../dist/fonts/Lato-Black.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Raleway-BlackItalic';
src: url('../../dist/fonts/Lato-BlackItalic.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Raleway-Bold';
src: url('../../dist/fonts/Lato-Bold.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Raleway-BoldItalic';
src: url('../../dist/fonts/Lato-BoldItalic.ttf') ;
font-weight: normal;
font-style: normal;
}


@font-face {
font-family: 'Raleway-Italic';
src: url('../../dist/fonts/Lato-Italic.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Raleway-Light';
src: url('../../dist/fonts/Lato-Light.ttf') ;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: 'Raleway-LightItalic';
src: url('../../dist/fonts/Lato-LightItalic.ttf') ;
font-weight: normal;
font-style: normal;
}


@font-face {
font-family: 'Raleway-Regular';
src: url('../../dist/fonts/Lato-Regular.ttf') ;
font-weight: normal;
font-style: normal;
}
