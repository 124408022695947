h1,
h2,
h3,
h5,
h6 {
    font-family: $merriweather-bold;
    margin: 0px;
}
h4 {
    font-family: $raleway-regular;
}

h1 {
    @include font-size('h1');
    color: color('blue');
}

h2 {
    @include font-size('h2');
    color: color('red');
}

h3 {
    @include font-size('h3');
    letter-spacing: 1px;
    color: color('red');
    text-transform: uppercase;
}

h4 {
    @include font-size('h4');
    color: color('blue');
    font-weight: 600;
}

h5 {
    @include font-size('h5');
    color: color('blue');
    text-transform: uppercase;
}

h6 {
    @include font-size('h6');
    letter-spacing: 1px;
    color: color('red');
}

p {
    @include font-size('h6');
    font-family: $raleway-regular;
    line-height: 40px;
    color: color('blue','medium');
    a,strong {
        font-size: inherit;
        color: inherit;
        font-family: $raleway-bold;
    }
}
