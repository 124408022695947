.c-content__hero {
    position: relative;
    margin-bottom: 0px;
    background-color: white;
    border-bottom: 4px solid white;
    @include media(md) {
        top: -50px;
    }
}

.soliloquy-container {
    overflow: hidden !important;
}
