body {
    margin: 0;
    font-size: $base-font-size;
    font-family: $base-font-family;
    line-height: $base-line-height;
    color: $base-font-color;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x:hidden;
    background-image: url(/app/themes/sage/dist/images/bg-grey.png);
    background-repeat: repeat;
}

html {
    overflow-x:hidden;
}
hr {
    margin: 30px 0px;
    border-top: none;
    border: 2px solid color('grey');
}
