.c-rates {
    padding: 30px 0px;
    @include media(md) {
        padding: 60px 0px;
    }
    .c-btn {
        margin: 15px 0px;
    }
    h1,h2,h3,h4,h5,h6,p {
        padding-bottom: 15px;
    }
    img {
        display: block;
        margin: 0px auto;
    }
}
