.c-list {
    list-style: none;
    padding-left: rem(40px);
    margin-bottom: 15px;
}
.c-list li, .c-list li ul li  {
    color: color('blue');
    line-height: 38px;
    font-size: 22px;
    margin-bottom: 15px;
    font-family: $raleway-regular;
    p, a {
        color: color('blue');
        font-family: $raleway-regular;

    }
}
.c-list li:before {
    content: "";
    color: color('blue');
    position: absolute;
    margin-left: neg(rem(30px));
    margin-top: rem(10px);
    background-image: url(/app/themes/sage/dist/images/single-star.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 15px;
    height: 15px;
}
