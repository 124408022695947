.c-logo {
    position: relative;
    z-index: 150;
    display: block;
    margin: 0px auto;
    max-width: 230px;
    margin-bottom: -40px;
    margin-top: -30px;
    @include media(md) {
        margin-bottom: 0px;
        position: absolute;
        margin-left: -65px;
        max-width: 290px;
        z-index: 171;
    }
}
