.c-partners {
    padding: 60px 0px;
    background-image: url(/app/themes/sage/dist/images/bg-red.png);
    background-repeat: repeat;
    text-align: center;
    border-bottom: 4px solid white;
    border-top: 4px solid white;
    h1,h2,h3,h4,h5,h6,p {
        color: white;
        text-align: center;
        padding-bottom: 15px;
    }
    ul {
        list-style: none;
        width: auto;
        display: block;
        margin: 0px auto;
        padding: 0px;
        li {
            width: 49%;
            padding: 0px 45px;
            display: inline-block;
            margin: 0px auto;
            @include media(sm) {
                width: auto;
            }
            img {
                display: block;
                margin: 0px auto;
            }
        }
    }
}
