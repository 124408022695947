.c-header {
  background-image: url(/app/themes/sage/dist/images/bg-red.png);
  background-repeat: repeat;
  height: 50px;
  @include media(md) {
    height: 130px;
    background-color: white;
    background-image: none;
  }
  .c-header__right-menu,.c-header__left-menu {
    display: none;
    @include media(md) {
      display: block;
    }
  }
}


// Red bar for navigation menu
.c-nav--mobile {
  background-image: url(/app/themes/sage/dist/images/bg-red.png);
  background-repeat: repeat;
  width: 100%;
  height: 40px;
  position: fixed;
  bottom: 0px;
  display: block;
  z-index: 170;
  border-top: 4px solid white;
  @include media(md) {
    position: relative;
    top: -130px;
    border-top: none;

  }
  .c-btn {
    position: relative;
    float: right;
    border: 4px solid white;
    border-top: none;
    border-bottom: none;
    display: none;
    @include media(md) {
      display: block;
    }
  }
  .c-header__contact  {
    margin-left: 0px;
    list-style: none;
    width: auto;
    position: relative;
    top: -5px;
    .c-header__phone {
      display: block;
      width: 30px;
      position: relative;
      @include media(md) {
        display: none;
      }
    }
    .c-header__number {
      display: none;
      font-family: $raleway-regular;
      @include media(md) {
        display: inline-block;
      }
    }
  }
  li {
    display: inline-block;
    width: auto;
    a {
      width: auto;
      font-family: $raleway-bold;
      color: white;
      text-decoration: none;
      position: relative;
      top: 5px;
      font-size: 22px;
      left: -45px;
    }
    p {
      position: relative;
      font-family: $raleway-regular;
      top: 5px;
      display: none;
      font-size: 14px;
      color: white;
      @include media(lg) {
        display: block;
      }
    }
  }
}
.drop-menu,.drop-menu2  {
  color: color('blue');
  position: relative;
  top: 25%;
  width: 5px;
  left: -2px;
  display: none;
  @include media(md) {
    display: inline-block;
  }
  @include media(lg) {
    display: none;
  }
}
.drop-menu2 {
  left: -12px;
}
.menu-item-44 {
  .drop-menu {
    left:-10px;
  }
}
