input,
select,
textarea {
    width: 100%;
    padding: rem(10px);
    border: 1px solid color('grey', 'medium');
    border-radius: 0;
}

input[type="submit"] {
    width: auto;
}


// Form CSS

.gform_wrapper {
    margin-top: -50px;
    .ui-datepicker-trigger {
        box-shadow: none;
        border: none;
    }
    .gsection {
        margin-bottom:-30px;
    }
    .gfield_label {
        color: color('blue','medium');
        font-size: 22px !important;
        font-family: $raleway-bold;
    }
    label {
        color: color('blue','medium');
        font-family: $raleway-bold;
    }
    input {
        border-color: color('red');
        height: 40px;
        margin-bottom: 0px;
        color: color('red');
    }
    .gform_button {
        display: table !important;
        margin: 0px auto !important;
        padding: rem(0px) rem(10px);
        color: white;
        border: none;
        text-decoration: none;
        font-family: $raleway-bold;
        background-color: color('red');
        &:hover {
            background-color: color('red', 'medium');
        }
        a {
            font-size: 24px;
            color: white;
            text-decoration: none;
            &:hover {
                color: white;
            }
        }
    }
    select, option {
        border-color: color('red');
        height: 40px;
        color: color('red');
    }
     .gfield {
        border: none;
    }
.gsection_title {
    font-size: 50px;
    margin-bottom: 15px;
}

}
