.c-footer {
    padding-top: 30px;
    padding-bottom: 45px;
    background-color: white;
    @include media(md) {
        padding-top: 60px;
        padding-bottom: 15px;
    }
    p,a {
        color: color('blue','medium');
        text-decoration: none;
        text-align: center;
        padding-bottom: 15px;
        @include media(md) {
            text-align: left;
        }
    }
    ul {
        list-style: none;
        padding: 0px;
        width: auto;
        position: relative;
        display: table;
        margin: 0px auto;
        padding-bottom: 15px;
        @include media(md) {
            display: block;
        }
        li {
            display: inline-block;
            width: auto;
            padding-right: 5px;
        }
    }
    .c-footer__copyright {
        font-size: 14px;
        text-align: center;
    }
    .c-social {
        text-align: center
    }
    iframe {
        box-shadow: 0px 0px 10px rgba(black, .5);
        border: 5px solid white !important;
    }
}
