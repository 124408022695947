.c-events{
    background-image: url(/app/themes/sage/dist/images/events-background.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px 0px;
    border-bottom: 4px solid white;
    border-top: 4px solid white;
    @include media(md) {
        padding: 60px 0px;
    }
    h1,h2,h3,h4,h5,h6,p {
        text-align: center;
        padding-bottom: 15px;

    }
    h1,h2,h3,h4,h5,h6 {
        position: relative;
        top: 0px;
        overflow: hidden;
        padding: 0px;
        @include media(md) {
            top: -30px;
        }
        &::before {
            content: "";
            width: 50%;
            height: 2px;
            background-color: white;
            position: absolute;
            margin-left: -54%;
            top: 30px;
            display: none;
            @include media(md) {
                display: inline-block;
            }
        }
        &::after {
            content: "";
            width: 50%;
            height: 2px;
            background-color: white;
            position: absolute;
            margin-left: 4%;
            top: 30px;
            display: none;
            @include media(md) {
                display: inline-block;
            }
        }
    }
    .c-btn {
        margin: 15px auto;
    }
    .o-col {
        @include media(xs) {
            padding: 0px;
        }
    }
    .o-container {
        border: none;
        @include media(md) {
            border: 2px solid white;
            border-top: none;
        }
    }
    p {
        padding: 0px 15px;
    }
}
